import React from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import { Grid } from '@mui/material';
import ContextualAction from './ContextualAction';

const ContextualActions = ({ actions }) => (
  <Grid>
    {actions.map((action) => (
      <ContextualAction key={action.label} {...action} />
    ))}
  </Grid>
);

ContextualActions.propTypes = {
  actions: arrayOf(
    shape({
      label: string,
      Icon: node,
      href: string,
      description: string,
    }),
  ).isRequired,
};

export default ContextualActions;
