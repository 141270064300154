import React, { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { string, bool, node } from 'prop-types';
import {
  StarBorderOutlined as StarBorderOutlinedIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  BallotOutlined as BallotOutlinedIcon,
} from '@mui/icons-material';
import TimelineIconRoot from '../TimelineIconRoot';
import ReservationContext from '../../../../../providers/ReservationContext';
import { track } from '../../../../../modules/segment/helpers';

const TimelineIcon = ({ label, icon, active, completed, className }) => {
  const theme = useTheme();
  const { orderCode } = useContext(ReservationContext);

  const icons = {
    1: <BallotOutlinedIcon onClick={() => track('Timeline - Prep', { orderID: orderCode })} />,
    2: <StarBorderOutlinedIcon onClick={() => track('Timeline - Move', { orderID: orderCode })} />,
    3: <AddBoxOutlinedIcon onClick={() => track('Timeline - Post', { orderID: orderCode })} />,
  };

  return (
    <Box sx={{ position: 'relative', top: 0 }}>
      <Typography
        variant='body1'
        fontFamily={active ? 'CalibreMedium' : 'Calibre'}
        color={active ? theme.palette.text.primary : theme.palette.neutral.medium}
        sx={{
          position: 'absolute',
          top: '-30px',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
      <TimelineIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(icon)]}
      </TimelineIconRoot>
    </Box>
  );
};

TimelineIcon.propTypes = {
  label: string.isRequired,
  active: bool.isRequired,
  className: string,
  completed: bool.isRequired,
  icon: node.isRequired,
};

TimelineIcon.defaultProps = {
  className: '',
};

export default TimelineIcon;
