export default [
  {
    id: 'movePrep',
    name: 'Prep',
  },
  {
    id: 'moveDay',
    name: 'Move',
  },
  {
    id: 'postMove',
    name: 'Post',
  },
];
