import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const standardSlashFormat = (date, isUTC = false) =>
  isUTC ? dayjs.unix(date).utc().format('MM/DD/YYYY') : dayjs.unix(date).format('MM/DD/YYYY');

const apiDateFormat = ({ date, timezone = null }) => {
  if (timezone) return dayjs.unix(date).tz(timezone).format('YYYY-MM-DD');
  return dayjs.unix(date).format('YYYY-MM-DD');
};
const longMonthFormat = (date) => dayjs.unix(date).format('MMMM D, YYYY');

const longSentenceFormat = (date) => dayjs.unix(date).format('ddd, MMMM D, YYYY');

export { standardSlashFormat, apiDateFormat, longMonthFormat, longSentenceFormat };
