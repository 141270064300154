import React from 'react';
import { string, bool } from 'prop-types';
import NextLink from 'next/link';
import { ListItem, Typography, useTheme, Box } from '@mui/material';

const ContextualAction = ({ label, href, description, active, disabled }) => {
  const theme = useTheme();
  return (
    <NextLink href={disabled ? '#' : href} passHref sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <ListItem
        button
        component='a'
        sx={{
          background: theme.palette.neutral.lightest,
          width: '90%',
          margin: '0 auto',
          border: `1px solid ${theme.palette.neutral.light}`,
          borderRadius: '10px',
          '& + &': {
            marginTop: 2,
            [theme.breakpoints.up('md')]: {
              marginTop: 4,
            },
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            padding: 0,
            '&:after': {
              content: "''",
              display: 'block',
              position: 'absolute',
              top: 0,
              bottom: 0,
              background: active ? theme.palette.primary.main : theme.palette.text.default,
              width: '8px',
              height: '90%',
              transform: 'translateY(10%)',
              borderRadius: '20px',
            },
            '& > p': {
              marginLeft: 3,
            },
            '& .MuiTypography-root:first-of-type': {
              paddingTop: 0.5,
            },
            '& .MuiTypography-root:last-of-type': {
              paddingBottom: 0.5,
            },
          }}
        >
          <Typography variant='h4' component='p' lineHeight='1.2' color={active ? 'inherit' : theme.palette.text.default}>
            {label}
          </Typography>
          <Typography
            variant='body1'
            component='p'
            lineHeight='1.2'
            color={active ? theme.palette.neutral.medium : theme.palette.text.default}
          >
            {description}
          </Typography>
        </Box>
      </ListItem>
    </NextLink>
  );
};

ContextualAction.propTypes = {
  label: string.isRequired,
  href: string.isRequired,
  description: string.isRequired,
  active: bool,
  disabled: bool,
};

ContextualAction.defaultProps = {
  active: true,
  disabled: false,
};

export default ContextualAction;
