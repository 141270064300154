import React, { useCallback } from 'react';
import { StepButton, StepLabel, useTheme } from '@mui/material';
import { func, string } from 'prop-types';
import TimelineIcon from './TimelineIcon';

const TimelineItem = ({ label, onClick }) => {
  const theme = useTheme();
  const TimelineLabelIcon = useCallback((props) => <TimelineIcon label={label} {...props} />, [label]);

  return (
    <StepButton
      sx={{
        padding: theme.spacing(3, 1.5, 1),
      }}
      disabled={false}
      disableRipple
      onClick={onClick}
    >
      <StepLabel
        StepIconComponent={TimelineLabelIcon}
        sx={{
          '&.Mui-disabled': { cursor: 'pointer' },
          '& .MuiStepLabel-label.MuiStepLabel-label': { margin: 0.5 },
        }}
      />
    </StepButton>
  );
};

TimelineItem.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
};
export default TimelineItem;
