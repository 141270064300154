// SHOW ME THE MONEY! https://www.youtube.com/watch?v=mBS0OWGUidc
const formatCurrency = ({ amount, currencyType = 'usd', isRounded, showDecimal }) => {
  const decimal = amount / 100;
  const total = isRounded ? Math.round(decimal) : decimal;
  let final = total;

  if (showDecimal) {
    if (total.toFixed(2).split('.')[1] !== '00') {
      final = parseFloat(total.toFixed(2));
    }
  }

  switch (currencyType) {
    case 'usd':
      return `${final.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: showDecimal ? 2 : 0,
        maximumFractionDigits: showDecimal ? 2 : 0,
      })}`;
    default:
      return `${final.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: showDecimal ? 2 : 0,
        maximumFractionDigits: showDecimal ? 2 : 0,
      })}`;
  }
};

export default formatCurrency;
