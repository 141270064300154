/* eslint-disable react/no-unstable-nested-components */
import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import Link from '../Link';

import { track } from '../../modules/segment/helpers';
import ReservationContext from '../../providers/ReservationContext';
import { longSentenceFormat } from '../../utils/datetime/formatDate';
import { standardTimeFormat } from '../../utils/datetime/formatTime';
import formatAddress from '../../utils/formatAddress';
import formatCurrency from '../../utils/monies/formatCurrency';
import getReservationTimeline from '../../utils/reservation/getReservationTimeline';
import Warning from '../shared/Warnings/OrangeOutlined';
import VerticalDivider from './VerticalDivider';
import useMarketplace from '../../hooks/useMarketplace';

const ReservationDetailsDesktop = () => {
  const reservation = useContext(ReservationContext);
  const {
    createdDate,
    locations,
    id: confirmationId,
    permissions,
    orderBalance,
    orderEstimatedBalance,
    jobs,
    partner,
    orderCode,
  } = reservation;

  const { isMarketPlace } = useMarketplace();

  useEffect(() => {
    const timeline = getReservationTimeline(reservation);
    track('Dashboard Reservation Screen Loaded', { confirmationId, orderId: orderCode, timeline });
  }, []);

  // Find packing addon from package service quotes
  const packingAddon = jobs?.find((job) => job.service.id === 'PACKINGSERVICE');

  const setMovingJob = packingAddon ? jobs?.[1] : jobs?.[0];

  const ScheduledPackageDisplay = () => (
    <>
      {packingAddon ? (
        <Typography variant='body2' color='textSecondary'>
          <b style={{ paddingRight: '4px' }}>Packing </b>
          {`${longSentenceFormat(packingAddon.start)} starting ${standardTimeFormat({
            datetime: packingAddon.start,
            zone: packingAddon.startTimezone,
            createdDate,
          })}`}
        </Typography>
      ) : null}

      <Typography variant='body2' color='textSecondary'>
        <b style={{ paddingRight: '6px' }}>Moving </b>
        {`${longSentenceFormat(setMovingJob.start)} starting ${standardTimeFormat({
          datetime: setMovingJob.start,
          zone: setMovingJob.startTimezone,
          createdDate,
        })}`}
      </Typography>
    </>
  );

  const ScheduledServicesDisplay = () =>
    jobs?.map((job) => {
      let serviceName = job.service.name;
      if (job.service.id === 'PACKINGSERVICE') {
        serviceName = 'Packing';
      }
      return (
        <Typography key={job.id} variant='body2' color='textSecondary'>
          <b style={{ paddingRight: '6px' }}>{serviceName} </b>
          {`${longSentenceFormat(job.start)} starting ${standardTimeFormat({
            datetime: job.start,
            zone: job.startTimezone,
            createdDate,
          })}`}
        </Typography>
      );
    });

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' }, pt: 3 }}>
      <Warning
        active={permissions?.showNonGuaranteed && !isMarketPlace}
        text='We can schedule this service but cannot guarantee it at this time. A member of our team will be in touch shortly.'
      />
      <Typography variant='h4'>Order: {confirmationId}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          mt: 1,
          height: 160,
          backgroundColor: (theme) => theme.palette.neutral.lightest,
          border: '1px solid',
          borderColor: (theme) => theme.palette.neutral.light,
          borderRadius: '10px',
          width: '100%',
        }}
      >
        <Box display='flex' pl={3} pr={2} mt={3}>
          <Box display='flex' flexDirection='column'>
            <Typography variant='body1' color='secondary' fontFamily='CalibreMedium'>
              SCHEDULED SERVICES
            </Typography>
            {partner?.code === 'PODS' ? <ScheduledServicesDisplay /> : <ScheduledPackageDisplay />}
          </Box>
        </Box>
        <Box display='flex' pl={3} mt={3}>
          <Box display='flex' flexDirection='column' width={300} alignSelf='center'>
            <Typography variant='body1' color='secondary' fontFamily='CalibreMedium'>
              LOCATIONS
            </Typography>
            {locations?.length > 2 ? (
              <>
                <Typography noWrap variant='body2' color='textSecondary'>
                  {formatAddress({ ...locations[0].address })}
                </Typography>
                <Typography noWrap variant='body2' color='textSecondary'>
                  {formatAddress({ ...locations[locations.length - 1].address })}
                </Typography>
              </>
            ) : (
              locations?.map(({ address }) => (
                <Typography noWrap key={address.line1} variant='body2' color='textSecondary'>
                  {formatAddress({ ...address })}
                </Typography>
              ))
            )}
          </Box>
        </Box>
        <Box pl={3} mt={3}>
          <VerticalDivider />
        </Box>
        <Box display='flex' pl={3} mt={3}>
          <Box display='flex' flexDirection='column'>
            <Typography variant='body1' color='secondary' fontFamily='CalibreMedium'>
              {permissions?.showTransactions ? 'BILLING' : 'DETAILS'}
            </Typography>
            {permissions?.showTransactions ? (
              <Typography variant='body2' color='textSecondary'>
                {`Balance due - ${formatCurrency({
                  amount: permissions?.postMove
                    ? orderBalance?.outstanding.amount
                    : orderEstimatedBalance?.outstanding?.amount,
                  currency: permissions?.postMove
                    ? orderBalance?.outstanding.currency
                    : orderEstimatedBalance?.outstanding?.currency,
                  showDecimal: true,
                })}`}
              </Typography>
            ) : null}
            <Link
              href='/details'
              onClick={() => track('Billing - See Full Details', { orderId: orderCode, confirmationId })}
            >
              See full details
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReservationDetailsDesktop;
