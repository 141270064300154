import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const TimelineConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.neutral.lightest,
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.neutral.lightest,
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.neutral.lightest,
    borderRadius: 1,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

export default TimelineConnector;
