import { useContext } from 'react';

import { LocalMallOutlined as LocalMallOutlinedIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { track } from '../../../modules/segment/helpers';

import isWithinDates from '../../../utils/datetime/isWithinDates';
import RESERVATION_STATUS from '../../../constants/reservationStatus';
import serviceQuoteIDs from '../../../constants/serviceQuoteIDs';
import ReservationContext from '../../../providers/ReservationContext';

const getQuickActionsData = () => {
  const { jobs, orderCode, status } = useContext(ReservationContext);

  const isPastStartDate = dayjs().isAfter(dayjs.unix(jobs?.[0]?.start), 'day');
  const hasPackingMaterials = Boolean(jobs?.some((job) => job.service.id === serviceQuoteIDs.PACKING_MATERIALS));
  const COMPLETED = status === RESERVATION_STATUS.COMPLETED;
  const CLOSED = status === RESERVATION_STATUS.CLOSED;
  const canShowPackingMaterials = Boolean(
    !isWithinDates(jobs?.[0]?.start, 5) && !isPastStartDate && !COMPLETED && !CLOSED && !hasPackingMaterials,
  );
  const packingMaterials = canShowPackingMaterials
    ? {
        Icon: LocalMallOutlinedIcon,
        label: 'Buy packing supplies.',
        href: 'https://packing-supplies.getbellhops.com/',
        target: '_blank',
        description: 'Get your packing kit shipped directly to your door.',
        linkText: 'Shop now',
        stepIDs: ['movePrep'],
        displayOnMobile: true,
      }
    : {};

  return [
    packingMaterials,
    {
      Icon: LocalMallOutlinedIcon,
      label: 'Quick assistance',
      href: `/contact`,
      description: 'Call or chat with a specialist now.',
      linkText: 'Contact now',
      stepIDs: ['movePrep', 'moveDay', 'postMove'],
      displayOnMobile: false,
      onClick: () => track('Add Ons and Extras - Contact Now', { orderID: orderCode }),
    },
  ];
};

export default getQuickActionsData;
