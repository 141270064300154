import React from 'react';
import { bool, node, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Warning = ({ active, children, text }) =>
  active && (
    <Box
      mb={2}
      py={0.5}
      pl={1}
      sx={{
        backgroundColor: (theme) => theme.palette.neutral.lightest,
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.warning.main}`,
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <Box pl={{ xs: 2, md: 1 }} display='flex' alignItems='center'>
        <InfoOutlinedIcon sx={{ color: (theme) => theme.palette.warning.main }} />
      </Box>
      <Box px={{ xs: 2, md: 1 }} display='flex' alignItems='center'>
        {text && (
          <Typography variant='caption' sx={{ color: (theme) => theme.palette.text.secondary, weight: 400 }}>
            {text}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );

Warning.propTypes = {
  active: bool,
  children: node,
  text: string,
};

Warning.defaultProps = {
  active: false,
  children: null,
  text: '',
};

export default Warning;
