import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import ReservationContext from '../providers/ReservationContext';
import useSessionStorage from './useSessionStorage';

const useMarketplace = () => {
  const { id, jobs } = useContext(ReservationContext);
  const [isMarketPlace, setIsMarketPlace] = useState(false);
  const [isOrderSecured, setIsOrderSecured] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showInfoStatus, setShowInfoStatus] = useState(false);
  const infoResKey = `reservationInfoModalShown-${id}`;
  const infoModalShown = useSessionStorage(infoResKey);

  const [showPartialModal, setShowPartialModal] = useState(false);
  const [showPartialStatus, setShowPartialStatus] = useState(false);
  const [partialJobStats, setPartialJobStats] = useState({ requestedNumOfPros: 0, availableNumOfPros: 0 });
  const partialResKey = `reservationPartialModalShown-${id}`;
  const partialModalShown = useSessionStorage(partialResKey);

  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showFailedStatus, setShowFailedStatus] = useState(false);
  const failedResKey = `reservationFailedModalShown-${id}`;
  const failedModalShown = useSessionStorage(failedResKey);

  useEffect(() => {
    if (jobs?.some((job) => job.marketplaceSupplyType)) {
      setIsMarketPlace(true);
    }
    if (jobs?.some((job) => job.marketplaceSupplyType === 'PROJECTED')) {
      setIsOrderSecured(false);
    } else {
      setIsOrderSecured(true);
    }
  }, [jobs]);

  // #region Info modal
  useEffect(() => {
    if (
      isMarketPlace &&
      isOrderSecured &&
      showInfoModal &&
      (showPartialModal || showPartialStatus || showFailedModal || showFailedStatus)
    ) {
      setShowInfoModal(false);
    } else if (
      isMarketPlace &&
      !isOrderSecured &&
      !infoModalShown &&
      !showInfoModal &&
      !showPartialModal &&
      !showPartialStatus &&
      !showFailedModal &&
      !showFailedStatus
    ) {
      setShowInfoModal(true);
    }
  }, [isMarketPlace, infoModalShown, showPartialModal, showPartialStatus, showFailedModal, showFailedStatus]);

  useEffect(() => {
    if (isMarketPlace && isOrderSecured && showInfoStatus && (showPartialStatus || showFailedStatus)) {
      setShowInfoStatus(false);
    } else if (isMarketPlace && !isOrderSecured && !showInfoStatus && !showPartialStatus && !showFailedStatus) {
      setShowInfoStatus(true);
    }
  }, [isMarketPlace, showInfoStatus, showPartialStatus, showFailedStatus]);

  const closeInfoModal = () => {
    sessionStorage.setItem(infoResKey, '1');
  };

  // #endregion

  // #region Partial modal
  useEffect(() => {
    const requestedNumOfPros = jobs.length ?? 0;
    const availableNumOfPros = jobs.filter((job) => job.marketplaceMatchStatus === 'FUNCTIONAL_OFFER').length ?? 0;
    const baseConditions =
      isMarketPlace &&
      availableNumOfPros > 0 &&
      requestedNumOfPros > availableNumOfPros &&
      jobs.every((job) => dayjs(job.marketplaceMatchExpiry).isBefore(dayjs().format()));

    if (baseConditions && !partialModalShown && !showFailedModal) {
      setPartialJobStats({ requestedNumOfPros, availableNumOfPros });
      setShowPartialModal(true);
    }

    if (baseConditions && !showFailedStatus) {
      setShowPartialStatus(true);
    }
  }, [isMarketPlace, jobs, partialModalShown, showFailedModal, showFailedStatus]);

  const closePartialModal = () => {
    sessionStorage.setItem(partialResKey, '1');
    setShowPartialModal(false);
  };

  const reShowPartialModal = () => {
    sessionStorage.removeItem(partialResKey);
    setShowPartialModal(true);
  };
  // #endregion

  // #region Failed modal
  useEffect(() => {
    if (isMarketPlace && jobs && jobs.every((job) => job.marketplaceMatchStatus === 'FAILED')) {
      setShowFailedStatus(true);

      if (!failedModalShown) {
        setShowFailedModal(true);
      }
    }
  }, [isMarketPlace, failedModalShown, jobs]);

  const closeFailedModal = () => {
    sessionStorage.setItem(failedResKey, '1');
  };

  // #endregion

  return {
    isMarketPlace,

    showInfoModal,
    showInfoStatus,
    closeInfoModal,

    showPartialModal,
    showPartialStatus,
    closePartialModal,
    partialJobStats,
    reShowPartialModal,

    showFailedModal,
    showFailedStatus,
    closeFailedModal,
  };
};

export default useMarketplace;
