import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import formatAddress from '../../../utils/formatAddress';

const Locations = ({ locations }) => (
  <Box>
    {locations?.map(({ id, address }, i) => (
      <Box mb={1} key={id}>
        <Box mt={i !== 0 ? -1 : 0} display='flex' alignItems='flex-start'>
          {i === locations.length - 1 ? (
            <LocationOnOutlinedIcon color='secondary' />
          ) : (
            <FiberManualRecordOutlinedIcon color='secondary' />
          )}
          <Box pl={1} maxWidth='calc(100% - 35px)'>
            <Typography noWrap>{formatAddress({ ...address })}</Typography>
          </Box>
        </Box>
        {i !== locations.length - 1 && (
          <Box
            sx={{
              mt: -2,
              pl: 1.15,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 1,
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color: (theme) => theme.palette.neutral.light,
                  height: 6,
                  width: 6,
                  mb: 0.5,
                  mt: 0.75,
                }}
              />
              <FiberManualRecordIcon
                sx={{
                  color: (theme) => theme.palette.neutral.light,
                  height: 6,
                  width: 6,
                  mb: 0.5,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    ))}
  </Box>
);

Locations.propTypes = {
  locations: arrayOf(
    shape({
      address: shape({
        line1: string.isRequired,
        line2: string.isRequired,
        city: string.isRequired,
        region: string.isRequired,
        country: string.isRequired,
        postalCode: string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default Locations;
