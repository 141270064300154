import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { node } from 'prop-types';
import { useSession } from 'next-auth/client';
import Login from '../Login';
import LoadingIndicator from '../LoadingIndicator';
import ReservationContext from '../../../providers/ReservationContext';
import AutoLogin from '../AutoLogin';

const Layout = ({ children }) => {
  const [session, loading] = useSession();
  const router = useRouter();
  const reservation = useContext(ReservationContext);

  const [autoLogin, setAutoLogin] = useState(router.query?.autoLogin);

  useEffect(() => {
    // If page loads with query, will set to true to load AutoLogin component
    // AutoLogin component removes query param and this sets back to false for main page re-render
    setAutoLogin(router.query?.autoLogin);
  }, [router.query]);

  if (autoLogin) return <AutoLogin />;
  if (reservation?.isLoading || loading)
    return <LoadingIndicator message='Getting your reservation' loadingReason='login or getReservation' />;
  if (!session) return <Login />;

  return children;
};

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
