import React, { useState, useEffect, useContext } from 'react';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { Box, Typography, useTheme } from '@mui/material';
import ReservationContext from '../../providers/ReservationContext';
import ContextualActions from './ContextualActions';
import BabyTimeline from './BabyTimeline';
import QuickActions from './QuickActions';
import stepsData from './progressMenuData/stepsData';
import getActionsData from './progressMenuData/actionsData';
import getQuickActionsData from './progressMenuData/quickActionsData';
import RESERVATION_STATUS from '../../constants/reservationStatus';

const ProgressMenu = () => {
  dayjs.extend(isToday);

  const theme = useTheme();
  const { status, transactions, jobs, permissions } = useContext(ReservationContext);

  const [stage, setStage] = useState('');
  const [step, setStep] = useState(0);

  const actions = getActionsData({ transactions, status, jobs, permissions })
    .filter(({ context = [] }) => context.some(({ id = '' }) => id === stage))
    .sort(({ context: contextA = {} }, { context: contextB = {} }) => {
      const { priority: priorityA = 100 } = contextA.find(({ id = '' }) => id === stage);
      const { priority: priorityB = 100 } = contextB.find(({ id = '' }) => id === stage);

      return priorityA - priorityB;
    });

  const quickActions = getQuickActionsData()?.filter(({ stepIDs = [] }) => stepIDs.includes(stage));

  useEffect(() => {
    if (stage) {
      setStep(stepsData.findIndex(({ id }) => id === stage));
    }
  }, [stage]);

  useEffect(() => {
    if (!jobs) return;

    const reservationStartDate = dayjs.unix(jobs[0].start);

    if (status === RESERVATION_STATUS.COMPLETED) {
      setStage('postMove');
    } else if (reservationStartDate.isToday()) {
      setStage('moveDay');
    } else {
      setStage('movePrep');
    }
  }, []);

  const updateTimeline = (selectedStage) => {
    setStage(selectedStage);
  };

  return (
    <Box display={{ xs: 'block', md: 'flex' }} alignItems='start' marginTop={{ xs: 0, md: 3.5 }}>
      <Box flexGrow={2}>
        <Typography display={{ xs: 'none', md: 'block' }} variant='h4' component='h2' sx={{ marginBottom: 2 }}>
          Timeline and actions
        </Typography>
        <Box
          padding={{ xs: 0, md: theme.spacing(6, 0) }}
          bgcolor={{ md: theme.palette.neutral.lightest }}
          borderRadius={{ xs: 0, md: '10px' }}
          sx={{
            border: {
              xs: 'none',
              md: `1px solid ${theme.palette.neutral.light}`,
            },
          }}
        >
          <BabyTimeline steps={stepsData} activeStep={step} onClick={updateTimeline} />
          <Box marginTop={4}>
            <ContextualActions actions={actions} />
          </Box>
        </Box>
      </Box>
      <Box flexGrow={1} maxWidth={{ md: 300 }} ml={{ md: 6 }}>
        <Typography display={{ xs: 'none', md: 'block' }} variant='h4' component='h2'>
          Add-ons and extras
        </Typography>
        <QuickActions quickActions={quickActions} />
      </Box>
    </Box>
  );
};

export default ProgressMenu;
