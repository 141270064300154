import { TRANSACTION_PAYMENT_STATUS_API, TRANSACTION_SOURCE_API } from '../../../constants/transactions';
import RESERVATION_STATUS from '../../../constants/reservationStatus';
import useSystem from '../../../hooks/useSystem';

export default ({ transactions, status, jobs, permissions }) => {
  const { progressActionStates: { preMoveContract, postMoveContract } = {} } = useSystem();

  const finalTransaction = transactions?.find((txn) => txn.source.toLowerCase() === TRANSACTION_SOURCE_API.FINAL_PAYMENT);

  const getReceiptOptions = () => {
    if (
      finalTransaction &&
      status !== RESERVATION_STATUS.COMPLETED &&
      finalTransaction.paymentStatus.toLowerCase() !== TRANSACTION_PAYMENT_STATUS_API.CAPTURED
    )
      return {
        description: 'Receipts are available when your final bill is paid.',
      };
    return {
      description: 'View your receipt.',
    };
  };

  const getMeetYourTeamOptions = () => {
    const jobSlots = ['hhgLead', 'lead', 'hhgPros', 'trucks', 'pros', 'recruits'];
    if (
      jobs?.every((job) => {
        const filteredSlots = jobSlots.filter((slot) => Boolean(job.slots[slot])); // only get slots used in the job.
        const rolesFulfilled = filteredSlots.map((slot) => job.slots[slot].workers.every((worker) => Boolean(worker.user))); // check if each slot actually used in the job is populated.

        return rolesFulfilled.every((t) => t === true);
      })
    ) {
      return {
        description: 'Your team is assembled.',
      };
    }
    return {
      description: 'We’re assembling your team.',
    };
  };

  const qrCodeDescription = () => {
    if (permissions?.postMove) return 'Your move is complete';
    if (permissions?.qrActive) return 'This code is used to begin/end your service';
    return "We'll notify you when your code is available";
  };

  const actions = [
    ...(preMoveContract?.required
      ? [
          {
            label: `Sign pre-move docs.`,
            href: '/contracts',
            description: 'Your documents must be signed pre-move.',
            context: [
              { id: 'movePrep', priority: 1 },
              { id: 'moveDay', priority: 1 },
            ],
            active: !preMoveContract?.signed,
            disabled: preMoveContract?.signed,
          },
        ]
      : []),
    ...(postMoveContract?.required
      ? [
          {
            label: `Sign post-move docs.`,
            href: '/contracts',
            description: 'View and sign post-move agreements.',
            context: [{ id: 'postMove', priority: 1 }],
            active: !postMoveContract?.signed,
            disabled: postMoveContract?.signed,
          },
        ]
      : []),
    {
      label: 'View helpful resources.',
      href: '/resources',
      description: 'Check out our moving tips and tricks.',
      context: [
        { id: 'movePrep', priority: 1 },
        { id: 'moveDay', priority: 3 },
        { id: 'postMove', priority: 3 },
      ],
    },
    permissions?.deltaEnabled && {
      label: 'Get your QR code',
      href: '/qrcode',
      description: qrCodeDescription(),
      context: [{ id: 'moveDay', priority: 1 }],
      active: permissions.qrActive,
      disabled: !permissions.qrActive && permissions.postMove,
    },
    {
      label: 'Modify reservation notes.',
      href: '/manage/notes',
      description: 'Add instructions or helpful info for your team.',
      context: [
        { id: 'movePrep', priority: 2 },
        { id: 'moveDay', priority: 4 },
      ],
    },
    permissions?.meetTeamEnabled && {
      label: 'Meet your team.',
      href: '/team',
      context: [
        { id: 'movePrep', priority: 3 },
        { id: 'moveDay', priority: 2 },
      ],
      ...getMeetYourTeamOptions(),
    },
    {
      label: permissions?.allowTip ? 'Tip and review team.' : 'Review team.',
      href: '/review',
      description: permissions?.allowTip ? 'Tip and review the crew.' : 'Review the crew.',
      context: [{ id: 'postMove', priority: 1 }],
    },
    permissions?.showReceipt && {
      label: 'View receipt.',
      href: '/documents/receipt',
      context: [{ id: 'postMove', priority: 2 }],
      ...getReceiptOptions(),
    },
  ];

  return actions;
};
