import { styled } from '@mui/material/styles';

const TimelineIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.neutral.lightest,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  borderRadius: '50%',
  color: theme.palette.secondary.main,
  zIndex: 1,
  '&:hover': {
    background: theme.palette.secondary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.neutral.lightest,
    },
  },
  '& svg': {
    height: 32,
    width: 32,
  },
  ...(!ownerState.active && {
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  }),
  ...(ownerState.active && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.lightest,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.neutral.lightest,
    color: theme.palette.secondary.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.secondary.main,
  },
}));

export default TimelineIconRoot;
