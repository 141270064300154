import React from 'react';
import { shape, string, elementType, arrayOf } from 'prop-types';
import { List, useTheme } from '@mui/material';
import QuickActionCard from './QuickActionCard';

const QuickActions = ({ quickActions }) => {
  const theme = useTheme();

  return (
    <List
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(0, 2),
        width: { xs: '90%', lg: '100%' },
        margin: '0 auto',
        padding: 0,
        [theme.breakpoints.up('md')]: {
          flexDirection: 'column',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          margin: 0,
          padding: 0,
        },
      }}
    >
      {quickActions.map((qa) => (
        <QuickActionCard key={qa.label} {...qa} />
      ))}
    </List>
  );
};

QuickActions.propTypes = {
  quickActions: arrayOf(
    shape({
      label: string,
      href: string,
      description: string,
      Icon: elementType,
      linkText: string,
    }),
  ).isRequired,
};

export default QuickActions;
