import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const RELEASE_DATE = '12/02/2021'; // time windows code release date

dayjs.extend(utc);
dayjs.extend(timezone);

const hourOnlyFormat = (datetime, zone) => dayjs.unix(datetime).tz(zone).format('h A');
const timeLogFormat = (datetime, zone) => dayjs(datetime).tz(zone).format('h:mma');
const apiFormat = (datetime, zone) => dayjs.unix(datetime).tz(zone).format('hh:mm');

const standardTimeFormat = ({ datetime, zone, createdDate }) => {
  if (dayjs.unix(createdDate).isBefore(dayjs(RELEASE_DATE), 'day')) {
    return dayjs.unix(datetime).tz(zone).format('h A');
  }

  const hourWithMeridiem = dayjs.unix(datetime).tz(zone).format('h A');
  const string = hourWithMeridiem.split(' ');
  const hour = string[0];
  const meridiem = string[1];
  const endTime = Number(hour) + 1;
  const changeToPM = endTime === 12 ? 'PM' : meridiem;

  return `${hour}-${endTime}:00 ${changeToPM}`;
};

export { hourOnlyFormat, standardTimeFormat, apiFormat, timeLogFormat };
