import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import ProgressMenu from '../components/ProgressMenu';
import ReservationDetailsDesktop from '../components/ReservationDetailsDesktop';
import ReservationDetailsTab from '../components/ReservationDetailsTab';
import BasicLayout from '../components/shared/BasicLayout';
import { track } from '../modules/segment';
import ReservationContext from '../providers/ReservationContext';

const DashboardHome = () => {
  const reservation = useContext(ReservationContext);

  const { paymentMethod, orderCode: orderId, partnerCode: partnerId, id: confirmationId } = reservation;

  const [paymentMethodCheck, setPaymentMethodCheck] = useState(false);

  useEffect(() => {
    if (orderId && confirmationId && !paymentMethod && !paymentMethodCheck) {
      setPaymentMethodCheck(true);
      track('Dashboard - Payment Method missing', {
        orderId,
        confirmationId,
        partnerId,
      });
    }
  }, [paymentMethod, orderId, confirmationId, partnerId]);

  return (
    <BasicLayout>
      <Box
        sx={{
          height: '100%',
          minHeight: 'calc(100vh - 92px)',
          backgroundImage: 'linear-gradient(360deg, rgba(155, 184, 248, 0.25), 0%, rgba(205, 219, 251, 0.25) 100%)',
          pb: 10,
        }}
      >
        <Box sx={{ maxWidth: '924px', margin: '0 auto' }}>
          <ReservationDetailsTab />
          <ReservationDetailsDesktop />
          <ProgressMenu />
        </Box>
      </Box>
    </BasicLayout>
  );
};

export default DashboardHome;
