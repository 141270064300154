import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import ReservationContext from '../../providers/ReservationContext';
import { longSentenceFormat } from '../../utils/datetime/formatDate';
import { standardTimeFormat } from '../../utils/datetime/formatTime';
import Divider from '../shared/Divider';
import Locations from '../shared/Locations';
import Warning from '../shared/Warnings/OrangeOutlined';
import useMarketplace from '../../hooks/useMarketplace';

const ReservationDetailsTab = () => {
  const { id, createdDate, jobs, locations, permissions } = useContext(ReservationContext);
  const [open, setOpen] = useState(false);
  const { isMarketPlace } = useMarketplace();

  const ServiceDates = () =>
    jobs?.map((job, i, { length }) => (
      <Typography key={job.id} sx={{ pb: length - 1 === i ? 2 : 0, color: (theme) => theme.palette.neutral.medium }}>
        {`${longSentenceFormat(job?.start)} starting ${standardTimeFormat({
          datetime: job?.start,
          zone: job?.startTimezone,
          createdDate,
        })}`}
      </Typography>
    ));

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <Accordion
        expanded={open}
        onChange={() => setOpen(!open)}
        sx={{
          display: 'flex',
          width: '100%',
          mx: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '&:first-of-type': {
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h3'>Order: {id}</Typography>
            <ServiceDates />
            {open ? (
              <Divider />
            ) : (
              <Button sx={{ height: 10 }} onClick={() => setOpen(true)}>
                See more
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mt: -2,
            }}
          >
            <Locations locations={locations} />
            <Box pt={3}>
              <Button variant='outlined' href='/details'>
                See full details
              </Button>
              <Button onClick={() => setOpen(false)}>See less</Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box pt={2} px={1}>
        <Warning
          active={permissions?.showNonGuaranteed && !isMarketPlace}
          text='We can schedule this service but cannot guarantee it at this time. A member of our team will be in touch shortly.'
        />
      </Box>
    </Box>
  );
};

export default ReservationDetailsTab;
