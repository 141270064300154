export default ({ line1, line2, city, region, postalCode }) => {
  const sentenceCase = (str = '') =>
    str
      .split(' ')
      .map((term) =>
        term
          .split('')
          .map((c, i) => (i === 0 ? c.toUpperCase() : c.toLowerCase()))
          .join(''),
      )
      .join(' ');
  let address = `${sentenceCase(line1)}, ${sentenceCase(city)}, ${region} ${postalCode}`;
  if (line2) {
    address = `${sentenceCase(line1)} #${line2}, ${sentenceCase(city)}, ${region} ${postalCode}`;
  }
  return address;
};
