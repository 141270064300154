import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { Step, Stepper, useTheme } from '@mui/material';
import TimelineItem from './TimelineItem';
import TimelineConnector from './TimelineConnector';

const BabyTimeline = ({ activeStep, steps, onClick }) => {
  const theme = useTheme();

  return (
    <div>
      <Stepper
        sx={{
          padding: theme.spacing(6, 0, 1),
          [theme.breakpoints.up('md')]: {
            paddingTop: 2,
          },
        }}
        activeStep={activeStep}
        alternativeLabel
        connector={<TimelineConnector />}
      >
        {steps.map(({ id, name }) => (
          <Step key={id} sx={{ paddingLeft: '7px' }}>
            <TimelineItem label={name} onClick={() => onClick(id)} />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

BabyTimeline.propTypes = {
  steps: arrayOf(
    shape({
      id: string,
      name: string,
      actions: arrayOf(string),
    }),
  ).isRequired,
  activeStep: number.isRequired,
  onClick: func.isRequired,
};
export default BabyTimeline;
